
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'training-implementation-progress',
  components: {
    Datatable,
    ElNotification,
    Multiselect,
  },
  data() {
    return {
      data: {},
      tranche_id: [] as any,
      start_date: '',
      end_date: '',
      tranches: [] as any,
      progressData: [],
      optionsTranche: [] as any,
      loading: false,
      load: false,
      componentKey: 0,
      showinstitutionList: false,
      api_url: '',
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranche();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);

      let data = `${this.VUE_APP_API_URL}/api/report/implementation_progress_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      await ApiService.post('report/implementation_progress_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/implementation_progress_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Implementation Progress Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.tranches.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getProgressData() {
      if (this.tranche_id != '') {
        this.load = true;
        await ApiService.get(
          'report/implementation-progress?tranche_id=' +
          this.tranche_id +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
        )
          .then((response) => {
            this.progressData = response.data.data;
            this.showinstitutionList = true;
            this.componentKey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please Select Tranche!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },
  },
});
